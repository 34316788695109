<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="接口地址">
        <el-input v-model="search.keywords" placeholder="请输入接口" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="admin_id"
              label="用户"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="path"
              label="接口">
      </el-table-column>
      <el-table-column
              prop="content"
              label="内容">
      </el-table-column>
      <el-table-column
              prop="fip"
              label="IP"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="操作时间"
              align="center"
              width="140">
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[15, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 15,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.merchant.merchantVisitLog(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
